import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import D2CForm from "../components/DSA Components/D2CForm"
import { AlertMessage } from "../components/alert"
import PageImage from "../components/Affiliate Components/image"
import PillsAndVideo from "../components/Affiliate Components/pillsAndVideo"

import "../css/tataclass.css"

import PropTypes from "prop-types"
import logo from "../images/Tata_Studi_Box_logo.svg"

export default class D2CTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formName: "",
    }
  }

  propTypes = {
    data: PropTypes.string.isRequired,
  }

  handleMessage = value => {
    this.setState({ formName: value })
  }

  render() {
    return (
      
      <div>
        <SEO
          title="Tata Studi: Class 1-10 Learning App for CBSE, NCERT"
          lang="en"
          description="Studi with Tata ClassEdge is a personalised e-Learning app for K-12 students of NCERT, CBSE, ICSE and State Boards. It’s based on principle of Science of Learning. Studi prepares students to be exam-ready through planning, concept videos and self-tests. It helps students plan, practice and perfect what they learn."
        />

        <div className="site_background">
          <h1 className="displayNone">TataClass</h1>
          <div className="container-fluid py-2">
            <p className="py-1 my-2 float-start  ">
              <Link to="/">
                <img
                  src={logo}
                  className="logo_tatapage"
                  alt="Tata Class Edge"
                />
              </Link>
            </p>
            {this.state.formName.length > 0 && (
              <div className="fixed-top">
                <AlertMessage
                  variant="alert-danger"
                  smallMsg="There was an error! Please check that you have filled all the details correctly!"
                  // smallMsg={`Dear ${this.state.formName} Thank you for your interest in Tata Studi. You will receive a call from our counsellor in your preferred time slot.`}
                  classes="form-alert"
                />
              </div>
            )}

            <br />
            <div className="row text-center mx-sm-1 mx-0 my-0 d-md-none d-block">
              <span style={{ color: "white" }}>
                <h3>
                  An after-school learning solution for class 1-10 (CBSE
                  Syllabus)
                </h3>
              </span>
            </div>
            <br />
            <div className="row mt-md-n5">
              <div className="col-sm-12 col-md-6 col-xl-4 px-0 text-center order-md-1 order-last">
                <PageImage />
              </div>

              <div className="col-sm-12 col-md-6 col-xl-5 my-5 my-sm-0 order-xl-2">
                <PillsAndVideo />
              </div>

              <div className="col-sm-12 col-md-10 col-lg-8 col-xl-3 order-xl-3 order-first my-md-5 my-xl-0 mt-0">
                <D2CForm
                  message={this.handleMessage}
                  error={this.handleError}
                  promoterid = {this.props.pageContext.content.field_agency_id}
                  agentid = {this.props.pageContext.content.field_agent_id}
                />
              </div>
            </div>
            <div className="row text-center mx-sm-1 mx-0 my-2 d-md-block d-none">
              <span style={{ color: "white" }}>
                <h3>
                  An after-school learning solution for class 1-10 (CBSE
                  Syllabus)
                </h3>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
