import React from "react"
import { navigate } from "gatsby"
import apiconfig from "../../../config/apiconfig"
import sfdcConfig from "../../../config/sfdcConfig"
import DatalayerEvents from "../../../services/datalayer"

let utm_source
let utm_medium
let utm_campaign
let utm_content
let gclid
let gaid


class D2CForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 0,
      datalayerEvents: new DatalayerEvents(),
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.formValidation = this.formValidation.bind(this)
    this.sendOTP = this.sendOTP.bind(this)
  }

  formValidation = function (fullName, email, contact, otp) {
    let nameRegex = new RegExp(/^[a-zA-Z ]*$/)
    let emailRegex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
    let contactRegex = new RegExp(/^[6-9]\d{9}$/)
    let otpRegex = new RegExp(/^\d{4}$/)
    let flag = false

    if (
      fullName &&
      nameRegex.test(fullName) &&
      email &&
      emailRegex.test(email) &&
      contact &&
      contactRegex.test(contact) &&
      otp &&
      otpRegex.test(otp)
    ) {
      flag = true
    }
    return flag
  }

  handleSubmit = function (event) {
    event.preventDefault()
    document.getElementById("tataform-btn").disabled = true
    const data = new FormData(event.target)
    if (data.get("radioBtn")) {
      let fullName = data.get("fullname")
      let email = data.get("email")
      let contact = data.get("mobnumber")
      let grade = data.get("grade")
      let otp = data.get("otp")
      let time = data.get("time")
      let pincode = data.get("pincode")
      let utm_src = utm_source
      let utm_mdm = utm_medium
      let utm_camp = utm_campaign
      let utm_cont = utm_content
      let gcl = gclid
      if (this.formValidation(fullName, email, contact, otp)) {
        // ----------------------------------------------

        let data = {
          txtname: fullName,
          txtemail: email,
          txtcontact: contact,
          btn_submit: "true",
          grade: grade,
          txtotp: otp,
          time: "",
          pincode: pincode,
          utm_src: utm_src,
          utm_mdm: utm_mdm,
          utm_camp: utm_camp,
          utm_cont: utm_cont,
          gclid: gcl,
          source: "D2C",
          promoterid: this.props.promoterid,
          agentid: this.props.agentid,
        }

        //PUSHING DATA TO BITNAMI MARIADB SQL
        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

        var urlencoded = new URLSearchParams()

        for (let key in data) {
          urlencoded.append(key, data[key])
        }

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        }

        var flag = 0

        fetch(apiconfig.DSAFORMSAPI, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result["code"] == 200) {
              flag = 1
              this.setState({
                status: 200
              })
              //USING THE AUTH TOKEN AND PUSHING LEAD TO SFDC
              let authToken = result["token"]
              var SFDCHeaders = new Headers()
              SFDCHeaders.append("Authorization", "Bearer " + authToken)
              SFDCHeaders.append("Content-Type", "application/json")

              let newData = [
                {
                  Lastname: fullName,
                  Email: email,
                  Phone: contact,
                  leadsource: "web",
                  pincode: pincode,
                  Grade: grade,
                  PreferredtimetoCall: time,
                  utmcampaign: utm_camp,
                  utmcontent: utm_cont,
                  utmmedium: utm_mdm,
                  utmterm: utm_src,
                  gclid: gcl,
                  promoterid: this.props.promoterid,
                  agentid: this.props.agentid,
                  subsource: "D2C"
                },
              ]

              var userData = JSON.stringify({
                datalist: newData,
              })

              var SFDCrequestOptions = {
                method: "POST",
                headers: SFDCHeaders,
                body: userData,
                redirect: "follow",
                mode: "cors",
              }

              fetch(sfdcConfig.LEADURL, SFDCrequestOptions)
                .then(response => {
                  return { res: response.json(), status: response.status }
                })
                .then(result => {
                  if (result.status == 200) {
                    navigate("/thankyou", {
                      state: "submitted",
                    })
                  } else {
                    this.props.message("error")
                    document.getElementById("tataform-btn").disabled = false
                  }
                })
                .catch(error => {
                  this.props.message("error")
                  document.getElementById("tataform-btn").disabled = false
                  console.log(error)
                })
            } else {
              document.getElementById("tataform-btn").disabled = false
              this.setState({ status: result["code"] })
              this.props.message("error")
              return
            }
          })
          .catch(error => {
            console.log(error, "err")
            document.getElementById("tataform-btn").disabled = false
          })
      } else {
        alert("Please fill all the fields accurately")
        document.getElementById("tataform-btn").disabled = false
      }
    } else {
      alert("Please Accept the T&C")
      document.getElementById("tataform-btn").disabled = false
    }
  }

  sendOTP = function () {
    let inputMobile = document.getElementById("mobnumber")
    if (!inputMobile.checkValidity()) {
      inputMobile.reportValidity()
      return
    }

    let mob = document.getElementById("mobnumber").value
    let otpHeaders = new Headers()
    otpHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    let otpurlencoded = new URLSearchParams()
    otpurlencoded.append("action", "send_otp")
    otpurlencoded.append("contact_no", mob)

    fetch(apiconfig.OTPAPI, {
      method: "POST",
      headers: otpHeaders,
      body: otpurlencoded,
      redirect: "follow",
    })
      .then(response => response.json())
      .then(result => {
        document.getElementById("clear").style.visibility = "hidden"
        setTimeout(() => {
          if (document.getElementById("clear")) {
            document.getElementById("clear").style.visibility = "visible"
          }
        }, 20000)
      })
      .catch(error => console.log("error", error))
  }
  componentDidMount() {
    if (window.location.search) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      utm_source = urlParams.get("utm_source")
      utm_medium = urlParams.get("utm_medium")
      utm_campaign = urlParams.get("utm_campaign")
      utm_content = urlParams.get("utm_content")
      gclid = urlParams.get("gclid")
    }

    let radioState
    document
      .getElementsByClassName("imCheckedDesktop")[0]
      .addEventListener("click", function (e) {
        if (radioState === this) {
          this.checked = false
          radioState = null
        } else {
          radioState = this
        }
      })
  }

  render() {
    return (
        <div id="contact-us-form h-100">
          <div className="contact_form_back" style={{ padding: "0" }}>
            <div className="row parent_row_contact_form">
              <div className="col-sm-12">
                <form
                  id="contactFormTata"
                  onSubmit={this.handleSubmit}
                  style={{ backgroundColor: "#37b8fb" }}
                >
                  <div className="cf_get_in_touch_label_tataclass">
                    <span className="tataclass-page-label">
                      Find out how Studi helps your child be exam ready
                    </span>
  
                    <div className="row tataclass-form-row">
                      <div className="col-sm-12">
                        <input
                          type="text"
                          id="fullname"
                          className="style_input_tatapage"
                          name="fullname"
                          pattern="^[A-Za-z0-9 ]+"
                          title="Only alphabets and numbers are allowed"
                          placeholder="Full Name*"
                          maxLength="25"
                          required
                        />
                      </div>
                      <div className="col-sm-12">
                        <lable>
                          <input
                            type="email"
                            id="email"
                            className="style_input_tatapage"
                            name="email"
                            placeholder="Email Address*"
                            required
                          />
                        </lable>
                      </div>
  
                      <div className="col-sm-12">
                        <input
                          type="text"
                          id="mobnumber"
                          name="mobnumber"
                          pattern="^[56789].+[0-9]+"
                          title="Number should start with 5, 6,7,8 or 9 
                            and length 10"
                          minLength="10"
                          maxLength="10"
                          className="style_input_tatapage"
                          placeholder="Mobile No.*"
                          required
                        />
                        <button
                          type="button"
                          id="clear"
                          className="cf_otp_btn_tatapage"
                          onClick={this.sendOTP}
                        >
                          {" "}
                          &nbsp;Get Otp&nbsp;{" "}
                        </button>
                      </div>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          id="otp"
                          className="style_input_tatapage"
                          name="otp"
                          minLength="4"
                          maxLength="4"
                          pattern="[0-9]+"
                          title="Please Enter correct OTP"
                          placeholder="OTP Verification"
                          required
                        />
                      </div>
  
                      <div className="col-sm-12">
                        <input
                          type="text"
                          id="pincode"
                          name="pincode"
                          pattern="^[0-9]+"
                          title="Enter 6-digit Pincode"
                          minLength="6"
                          maxLength="6"
                          className="style_input_tatapage"
                          placeholder="Pincode"
                          required
                        />
                      </div>
  
                      <div className="col-sm-12">
                        <select
                          id="grade"
                          name="grade"
                          className="select_form select_desktop_tatapage 
                              contact_form_grade_color"
                          required
                        >
                          <option value="" selected disabled hidden>
                            Grade{" "}
                          </option>
                          <option value="Class 1">Grade 1</option>
                          <option value="Class 2">Grade 2</option>
                          <option value="Class 3">Grade 3</option>
                          <option value="Class 4">Grade 4</option>
                          <option value="Class 5">Grade 5</option>
                          <option value="Class 6">Grade 6</option>
                          <option value="Class 7">Grade 7</option>
                          <option value="Class 8">Grade 8</option>
                          <option value="Class 9">Grade 9</option>
                          <option value="Class 10">Grade 10</option>
                          <option value="Class 11">Grade 11</option>
                          <option value="Class 12">Grade 12</option>
                        </select>
                      </div>
  
                      {/* <div className="col-sm-12">
                        <select
                          id="time"
                          name="time"
                          className=" select_form select_desktop_tatapage 
                              contact_form_grade_color"
                          required
                        >
                          <option value="" selected hidden disabled>
                            Preferred time to call{" "}
                          </option>
                          <option value="08AM-10AM">08AM-10AM</option>
                          <option value="10AM-12PM">10AM-12PM</option>
                          <option value="12PM-02PM">12PM-02PM</option>
                          <option value="02PM-04PM">02PM-04PM</option>
                          <option value="04PM-06PM">04PM-06PM</option>
                          <option value="06PM-09PM">06PM-09PM</option>
                        </select>
                      </div> */}
                    </div>
  
                    <div class="form-check cf_radio_div">
                      <input
                        class="form-check-input imCheckedDesktop"
                        type="radio"
                        name="radioBtn"
                        id="desktop_home_radio"
                        required
                        title="Please select the T&Cs"
                      />
                      <label
                        class="form-check-label cf_accept_label"
                        for="desktop_home_radio"
                      >
                        I Accept the
                        <a
                          className="cf_accept_label"
                          id="terms"
                          href="/termsandconditions/"
                          target="_blank"
                        >
                          <span className="t_and_c">T&amp;Cs</span>
                        </a>
                      </label>
                    </div>
                    <div>
                      <input
                        id="tataform-btn"
                        type="submit"
                        className="send_btn"
                        value="Submit"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  
export default D2CForm
